<template>
  <v-row>
     <v-col cols="12">
            <base-card>
     <v-card-title>Viaje
     </v-card-title>
       <v-card-text>
                    <v-tabs
                        background-color="white"
                        color="deep-purple accent-4"
                        right
                    >
                        <v-tab>Inicio de Viaje</v-tab>
                        <v-tab>Gastos</v-tab>
                        <v-tab>Evidencia</v-tab>
                        <v-tab>Cerrar Viaje</v-tab>
  <v-tab-item>
                            <v-container fluid>
                              <v-row>
                                <v-col cols="12">
                                <v-row>
                                    <v-col
                                      
                                        cols="2"
                                       
                                    >
                                       <v-text-field
                                 v-model="viaje.folio"
                                label="Folio"
                                type="number"
                                disabled="true"
                                
                            />  
                
                                    </v-col>
              </v-row>
              <v-row>
              
                <v-col cols="4">
                  <v-datetime-picker label="Elija fecha y hora"
                    v-model="viaje.fecha_carga"
                    clearText="Borrar"
                  
                   > </v-datetime-picker>

                </v-col>
              </v-row>
              <v-row>
                 <v-col cols="6">
                       <v-select
                     v-model="viaje.idcliente"
                      :items="lista_clientes"
                      item-text="nombre"
                      item-value="idcliente"
                      label="Cliente"
                    >

                    </v-select>
   
                 </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
        <v-select
                     v-model="viaje.idruta"
                      :items="lista_rutas"
                      item-text="descripcion"
                      item-value="idruta"
                      label="Ruta"
                    >

                    </v-select>

                </v-col>
                   <v-col cols="6">
                
        <v-select
                     v-model="viaje.idoperador"
                      :items="lista_operadores"
                      item-value="idoperador"
                      label="Operador"
                    >
 <template v-slot:selection="{ item }">{{item.nombre}} {{item.apellidos}}</template>
                    </v-select>

                </v-col>
              </v-row>
            
             
              <v-row>
                <v-col cols="6">
                     <v-select
                     v-model="viaje.tractocamion"
                      :items="lista_tractocamiones"
                      item-text="no_economico"
                      item-value="idtracto"
                      label="Unidad"
                    >
 <template v-slot:selection="{ item }">{{item.no_economico}} {{item.modelo}} {{item.marca}}</template>

                    </v-select>
                </v-col>
              </v-row>
            
<v-row v-if="viaje.tractocamion.idtipo == 2">
<v-col cols="3">
 <v-select
                     v-model="viaje.idremolque1"
                      :items="lista_remolques"
                      item-text="no_economico"
                      item-value="idremolque"
                      label="Remolque"
                    >
 <template v-slot:selection="{ item }">{{item.no_economico}} {{item.modelo}} {{item.marca}}</template>

                    </v-select>
</v-col>
<v-col cols="3">
   <v-checkbox
            v-model="checksectrailer"
            label="Agregar Segundo Remolque"
          />
</v-col>
</v-row>
          
             <v-row v-if="checksectrailer">
               <v-col cols="3">
 <v-select
                     v-model="viaje.idremolque2"
                      :items="lista_remolques"
                      item-value="idremolque"
                      label="Remolque 2"
                    >
 <template v-slot:selection="{ item }">{{item.no_economico}} {{item.modelo}} {{item.marca}}</template>

                    </v-select>
               </v-col>
               <v-col cols="2">
                  <v-select
                     v-model="viaje.iddolly"
                      :items="lista_dollys"
                      item-text="no_economico"
                      item-value="iddolly"
                      label="Dolly"
                    >

                    </v-select>
               </v-col>
             </v-row>
         <v-row>
           
                        <v-col cols="2">
                             <v-text-field
                                 v-model="viaje.monto_inicial"
                                label="Depósito inicial $"
                                @keypress="onlyForCurrency"
                              
                                
                            />  
                        </v-col>
                        <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Subir Archivo de Cartaporte"
></v-file-input>
                        </v-col>
         </v-row>

             <v-row>
               <v-col cols="6">
               <v-btn color="success">
                                <v-icon>mdi-content-save-outline</v-icon>

                 Guardar</v-btn>
               </v-col>
               <v-col cols="6">
                 <v-btn color="info"
                   :disabled="id == 0 || viaje.estado != 1"
                    @click="iniciarViaje"
                 >
                              

                 {{texto_inicio}}</v-btn>
               </v-col>
             </v-row>
            
              
               
              <br />

              <!-- <h3>Total: ${{suma_productos}}</h3>-->
            
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                          <v-tab-item>
                            <v-container fluid>
                                <v-row>
                                    <v-col
                                      
                                        cols="12"
                                        
                                    >
                                    <v-chip class="ma-2"       color="primary"
 label>
                                                             Total de Gastos: $ {{ gasto_total }}

                    </v-chip>
                      <v-chip class="ma-2"       color="primary"
 label>
                                                            Total de Depósitos: $ {{ deposito_total }}

                    </v-chip>
                      <v-chip class="ma-2"       color="primary" label>
                                                            Saldo: ${{ saldo_total }}

                    </v-chip>
           
          
                                    </v-col>
                                </v-row>
                                 <v-card-title>
         Gastos y Depósitos
        </v-card-title>
        <v-card-text>
          <v-expansion-panels
            v-model="panel"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Gastos</v-expansion-panel-header>
              <v-expansion-panel-content>
               <v-row>
                 <v-col cols="2">
                 <v-btn color="primary" @click="saveGasto">Guardar

                                                   <v-icon>mdi-content-save-outline</v-icon>

                 </v-btn>
                 </v-col>
                                  <v-col cols="2">

                    <v-btn color="primary" @click="addGasto">
               <v-icon>mdi-plus</v-icon>

                      Agregar Gasto
                    </v-btn>
                                  </v-col>
               </v-row>

                  <div v-for="(gasto, index) in lista_gastos" :key="index">
                    <v-row>
                      <v-col cols="3">
                           <v-text-field
                                v-model="gasto.concepto"
                                label="Concepto"
                                class="uppercase"
                                
                            />
                      </v-col>
                      <v-col cols="2">
                         <v-text-field
                                v-model="gasto.monto"
                                label="Importe $"
                                class="uppercase"
                                
                            />
                      </v-col>
                  
                       <v-switch
                        @change="acceptedText(gasto)"
                            v-model="gasto.aceptar"
                            class="ma-2"
                            :label="gasto.texto"
                        />
                    
                      <v-btn
                        color="danger"
                        @click="deleteGasto(index)"
                      >
               <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
             <v-expansion-panel-header>Depósitos</v-expansion-panel-header>
              <v-expansion-panel-content>
               <v-row>
                 <v-col cols="2">
                 <v-btn color="primary" @click="saveDeposito">Guardar

                                                   <v-icon>mdi-content-save-outline</v-icon>

                 </v-btn>
                 </v-col>
                                  <v-col cols="2">

                    <v-btn color="primary" @click="addDeposito">
               <v-icon>mdi-plus</v-icon>

                      Agregar Depósito
                    </v-btn>
                                  </v-col>
               </v-row>

                  <div v-for="(deposito, index) in lista_depositos" :key="index">
                    <v-row>
                      <v-col cols="3">
                           <v-text-field
                                v-model="deposito.concepto"
                                label="Concepto"
                                class="uppercase"
                                
                            />
                      </v-col>
                      <v-col cols="2">
                         <v-text-field
                                v-model="deposito.monto"
                                label="Importe $"
                                class="uppercase"
                                
                            />
                      </v-col>
                  
                      
                      <v-btn
                        color="danger"
                        @click="deleteDeposito(index)"
                      >
               <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
                            </v-container>
                        </v-tab-item>
                          <v-tab-item>
                            <v-container fluid>
                                <v-row>
                                    <v-col
                                      
                                        cols="12"
                                        md="4"
                                    >
                                      ???
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                          <v-tab-item>
                            <v-container fluid>
                                <v-row >
                                    <v-col
                                      
                                        cols="12"
                                        
                                    >
                                        <v-col cols="6" offset="2">
                  <v-datetime-picker label="Elija fecha y hora de descarga"
                    v-model="viaje.fecha_descarga"
                    clearText="Borrar"
                  
                   > </v-datetime-picker>

                </v-col>
                  
                                    </v-col>
                                   
                                </v-row>
                                <v-row>
                                   <v-col cols="3" offset="4">
                                        <v-btn color="success" @click="cerrarViaje">
                                          <v-icon>mdi-door-sliding-lock</v-icon>
            Cerrar Viaje
          </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-tab-item>
                    </v-tabs>
       </v-card-text>
            </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { url } from "../../main.js";
import swal from "sweetalert2";


export default {
  components: {
    
   
  },

  data() {
    return {
      id: this.$route.params.idviaje,
      permiso: 0,
      guardado: false,
      texto_inicio: "Iniciar Viaje",
      viaje: {
        folio: 0,
        fecha_carga: new Date().toISOString(),
        fecha_descarga: new Date().toISOString(),

        idruta: 0,
        idcliente: 0,
        idoperador: 0,
        estado: 0,
        tractocamion: {
          idtracto: 0,
        },

        idremolque1: 0,

        idremolque2: 0,

        iddolly: 0,
        producto: "",
        monto_inicial: 0,
      },
      lista_tractocamiones: [],
      lista_operadores: [],
      lista_remolques: [],
      lista_dollys: [],

      lista_clientes: [],
      lista_rutas: [],
      checksectrailer: false,
      cartaporte: {
        idcartaporte: 0,
        idviaje: this.$route.params.id,
        producto: "",
        observaciones: "",
        peso: 0,
        cantidad: 0,
        embalaje: "VIAJE",
        lista_conceptos: [],
      },
      concepto: { concepto: "", idcartaporte_detalle: 0, monto: 0 },
      lista_gastos: [],
      gasto_total: 0,
      gasto: {
        idgasto: 0,
        monto: 0,
        idviaje: this.$route.params.id,
        concepto: "",
        aceptar: false,
        adjunto: null,
        texto: "No Aceptado",
      },
      lista_depositos: [],
      deposito_total: 0,
      deposito: {
        iddeposito: 0,
        concepto: "",
        idviaje: this.$route.params.id,
        monto: 0,
      },
      saldo_total: 0,
    };
  },
  beforeMount() {},
  mounted() {
    this.getClientes();
    this.getOperadores();
    this.getRemolques();
    this.getDollys();
    if (this.id == 0) {
      this.getFolio();
      this.getTractocamiones();
    } else {
      this.getViaje();
      console.log(this.cartaporte);
      this.getCartaporte();
    }
  },
  methods: {
    getViaje() {
      this.axios
        .get(url + "apiViaje.php/viaje/" + this.id)
        .then((respuesta) => {
          console.log(respuesta);
          if (respuesta.data != null) {
            this.viaje.idviaje = respuesta.data.idviaje;
            this.viaje.folio = respuesta.data.folio;
            this.viaje.fecha_carga = new Date(
              respuesta.data.fecha_carga
            ).toISOString();
            if(respuesta.data.fecha_descarga!=null)
{          this.viaje.fecha_descarga = new Date(
              respuesta.data.fecha_descarga
            ).toISOString();
}
            this.viaje.idcliente = respuesta.data.idcliente;
            this.getTractocamiones(respuesta.data.idunidad);

            // this.viaje.tractocamion.idtracto=respuesta.data.idunidad;
            this.viaje.idremolque1 = respuesta.data.idremolque1;
            this.viaje.idremolque2 = respuesta.data.idremolque2;
            this.viaje.iddolly = respuesta.data.iddolly;
            this.viaje.idoperador = respuesta.data.idoperador;
            this.viaje.producto = respuesta.data.producto;
            this.viaje.monto_inicial = respuesta.data.monto_inicial;
            this.getDepositos();
            this.getGastos();

            this.viaje.estado = respuesta.data.estado;
            if (this.viaje.idremolque2 > 0) {
              this.checksectrailer = true;
            }

            if (this.viaje.estado > 1) {
              this.texto_inicio = "Iniciado";
            }
          }
          //  this.getDatosCliente(this.cliente.idcliente);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    getFolio() {
      this.axios
        .get(url + "apiViaje.php/nextfolio")
        .then((respuesta) => {
          if (respuesta.data != null) {
            this.viaje.folio = respuesta.data;
          }
          //  this.getDatosCliente(this.cliente.idcliente);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    saveViaje() {
      console.log(this.viaje);
      this.axios
        .post(url + "apiViaje.php/viaje", this.viaje)
        .then((respuesta) => {
          console.log(respuesta);
          if (respuesta.data != 0) {
            if (this.id == 0) {
              this.id = respuesta.data;
              this.$router.push("/viaje/" + this.id);
            } else {
            }
          }
        });
    },
    getClientes() {
      this.axios
        .get(url + "apiClientes.php/clientes")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_clientes = respuesta.data;
          this.viaje.idcliente = this.lista_clientes[0].idcliente;
          this.getRutas(this.viaje.idcliente);
          //  this.getDatosCliente(this.cliente.idcliente);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    
    getRutas(idcliente) {
      this.axios
        .get(url + "apiViaje.php/rutas/" + idcliente)
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_rutas = respuesta.data;
          this.viaje.idruta = this.lista_rutas[0].idruta;
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    getOperadores() {
      this.axios
        .get(url + "apiOperadores.php/operadores")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_operadores = respuesta.data;

          this.viaje.idoperador = this.lista_operadores[0].idoperador;
          console.log(this.lista_operadores);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    getTractocamiones(idunidad) {
      this.axios
        .get(url + "apiTractoCamiones.php/tractocamiones")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_tractocamiones = respuesta.data;

          if (this.id == 0) {
            this.viaje.tractocamion = this.lista_tractocamiones[0];
          } else {
            this.lista_tractocamiones.forEach((value, index) => {
              if (value.idtracto == idunidad) {
                this.viaje.tractocamion = this.lista_tractocamiones[index];
                console.log(this.viaje.tractocamion);
              }
            });
          }
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    getRemolques() {
      this.axios
        .get(url + "apiRemolques.php/remolques")
        .then((respuesta) => {
          this.lista_remolques = respuesta.data;
          if (this.tractocamion.idtipo == 2) {
            this.viaje.idremolque1 = this.lista_remolques[0].idremolque;
          }
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    setSecondRem() {
      if (this.checksectrailer) {
        this.viaje.idremolque2 = this.lista_remolques[0].idremolque;
      }
    },
    getDollys() {
      this.axios
        .get(url + "apiDollys.php/dollys")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_dollys = respuesta.data;
          if (this.checksectrailer) {
            this.viaje.iddolly = this.lista_dollys[0].iddolly;
          }
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },

    iniciarViaje() {
      var enviar = {};
      enviar.idviaje = this.viaje.idviaje;

      this.axios
        .post(url + "apiViaje.php/iniciar_viaje", enviar)
        .then((respuesta) => {
          if (respuesta.data.status == 200) {
            this.$swal("Viaje", "¡Viaje Iniciado!", "success");
            this.getViaje();
          }
          console.log(respuesta);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    acceptedText(gasto) {
      console.log(gasto);
      if (gasto.aceptar || gasto.aceptar == 1) {
        gasto.texto = "Aceptado";
        gasto.aceptar = true;
        this.gasto_total += parseFloat(gasto.monto);
      } else {
        gasto.texto = "No Aceptado";
        gasto.aceptar = false;
         this.gasto_total -= parseFloat(gasto.monto);
      }
    },
    addConcept() {
      this.concepto = {};
      this.idcartaporte_detalle = 0;
      this.concepto.monto = 0;
      this.concepto.concepto = "";

      this.cartaporte.lista_conceptos.push(Object.assign({}, this.concepto));
    },
    /**Funciones de Cartaporte */
    saveCartaporte() {
      this.axios
        .post(url + "apiCartaporte.php/cartaporte", this.cartaporte)
        .then((respuesta) => {
          console.log(respuesta);
          if (respuesta.data.status === 200) {
            this.$swal(respuesta.data.msg);
            this.getCartaporte();
          }
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    getCartaporte() {
      this.axios
        .get(url + "apiCartaporte.php/cartaporte/" + this.id)
        .then((respuesta) => {
          console.log(respuesta.data);
          if (respuesta.data != null) {
            this.cartaporte = respuesta.data;
          }
          //
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    downloadCartaporte() {
      window.open(
        "http://gruposaeta.com.mx/wsGSaeta/apiCartaporte.php/cartaporte_excel/" +
          this.cartaporte.idcartaporte,
        "_blank"
      );
    },
    deleteConcept(index) {
      this.cartaporte.lista_conceptos.splice(index, 1);
    },
    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || this.price.indexOf(".") != -1)
      ) {
        // 46 is dot
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        this.price != null &&
        this.price.indexOf(".") > -1 &&
        this.price.split(".")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    /**Funciones Gastos */
    addGasto() {
      this.gasto = {};
      this.gasto.idgasto = 0;
      this.gasto.monto = 0;
      this.gasto.idviaje = this.id;
      this.gasto.concepto = "";
      this.gasto.aceptar = false;
      this.gasto.adjunto = null;
      this.gasto.texto = "No Aceptado";

      this.lista_gastos.push(Object.assign({}, this.gasto));
    },
    saveGasto() {
      this.enviar = {};
      this.enviar.lista_gastos = this.lista_gastos;
      this.axios
        .post(url + "apiGastos.php/gasto", this.enviar)
        .then((respuesta) => {
          console.log(respuesta);
          this.$swal(respuesta.data.msg);
        });
    },
    getGastos() {
      this.axios
        .get(url + "apiGastos.php/gastos/" + this.id)
        .then((respuesta) => {
          console.log(respuesta.data);
          if (respuesta.data != null) {
            this.lista_gastos = respuesta.data;

            this.lista_gastos.forEach((element) => {
              this.acceptedText(element);
            });
          }
          //
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    deleteGasto(index) {
      this.lista_gastos.splice(index, 1);
    },
    addDeposito() {
      this.deposito = {};
      this.deposito.iddeposito = 0;
      this.deposito.idviaje = this.id;

      this.deposito.monto = 0;
      this.deposito.concepto = "";
      this.lista_depositos.push(Object.assign({}, this.deposito));
    },
    saveDeposito() {
      this.enviar = {};
      this.enviar.idviaje = this.id;
      console.log(this.id);
      this.enviar.lista_depositos = this.lista_depositos;
      this.axios
        .post(url + "apiGastos.php/deposito", this.enviar)
        .then((respuesta) => {
          console.log(respuesta);
          this.$swal(respuesta.data.msg);
        });
    },
    calcular_deposito() {
      this.deposito_total = parseFloat(this.viaje.monto_inicial);
      this.lista_depositos.forEach((element) => {
        this.deposito_total += parseFloat(element.monto);
      });

      this.saldo_total =
        parseFloat(this.deposito_total) - parseFloat(this.gasto_total);
    },

    deleteDeposito(index) {
      this.lista_depositos.splice(index, 1);
    },
    getDepositos() {
      this.axios
        .get(url + "apiGastos.php/depositos/" + this.id)
        .then((respuesta) => {
          console.log(respuesta.data);
          if (respuesta.data != null) {
            this.lista_depositos = respuesta.data;
           
              this.calcular_deposito();
            
          }
          //
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
    },
    cerrarViaje() {
  
      var enviar = {};
      enviar.idviaje = this.viaje.idviaje;
      enviar.fecha_descarga = this.viaje.fecha_descarga;
       

      this.axios
        .post(url + "apiViaje.php/cerrar_viaje", enviar)
        .then((respuesta) => {
          if (respuesta.data.status == 200) {
            this.$swal("Viaje", "¡Viaje Cerrado!", "success");
            this.getViaje();
          }
          console.log(respuesta);
        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
  },
};
</script>

